
import ApiService from '@/core/services/ApiService'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { AxiosRequestConfig } from 'axios'
import { event } from 'vue-gtag'

interface Teacher {
  _id: string
  firstName: string
  lastName: string
  photo?: string
  employee: {
    firstName: string
    lastName: string
  }
  levels?: string[]
  subjects?: string[]
}

interface MeetingData {
  topic: string
  desc: string
  salle: string
  duration: number
  date: string
  levels: string[]
  teachers?: Teacher[]
  advisor: Teacher
}

export default defineComponent({
  name: 'add-meeting-modal',
  components: {},
  props: {
    id: String,
  },

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null)
    const newTargetModalRef = ref<null | HTMLElement>(null)
    const loading = ref<boolean>(false)
    const loadingPage = ref<boolean>(true)

    const { t } = useI18n()
    const store = useStore()
    const levels = computed(() => store.getters.currentUser.levels)

    const attendance = ref('confirmed')
    const status = ref('')

    async function getMeetingData(id) {
      loadingPage.value = true
      await ApiService.get('/teachers/meeting/' + id)
        .then(({ data }) => {
          meeting.value = data
        })
        .catch((e) => {
          console.log(e)
        })

      ApiService.get('/teachers/meeting/attendance/' + id)
        .then(({ data }) => {
          if (data.status) {
            attendance.value = data.status
            status.value = data.status
          } else {
            attendance.value = 'confirmed'
            status.value = ''
          }
          loadingPage.value = false
        })
        .catch((e) => {
          console.log(e)
        })
    }

    watch(
      props,
      () => {
        getMeetingData(props.id)
      },
      { deep: true }
    )

    if (props.id) getMeetingData(props.id)

    const meeting = ref<MeetingData>({
      topic: '',
      desc: '',
      salle: '',
      duration: 0,
      date: '',
      levels: [],
      teachers: [],
      advisor: {
        _id: '',
        firstName: '',
        lastName: '',
        employee: {
          firstName: '',
          lastName: '',
        },
      },
    })

    const saveAttendance = () => {
      loading.value = true

      event('Send teacher attend/not', {
        event_category: 'Meeting',
        event_label: 'Meeting section',
        value: 1,
      })

      ApiService.put('/teachers/meeting/attendance/' + props.id, {
        status: attendance.value,
      } as AxiosRequestConfig)
        .then(() => {
          loading.value = false
          Swal.fire({
            text: t('meeting.submittedAttendance'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: t('course.okay'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })

          status.value = attendance.value
        })
        .catch(() => {
          Swal.fire({
            text: t('meeting.meetingError'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: t('course.okay'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          loading.value = false
        })
    }

    return {
      formRef,
      newTargetModalRef,
      loading,
      loadingPage,
      meeting,
      t,
      levels,
      moment,
      attendance,
      saveAttendance,
      status,
    }
  },
})
