
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import TeacherMeetings from "@/components/calendar/teacherMeetings.vue";

export default defineComponent({
  name: "teacher-meetings",
  components: {
    TeacherMeetings,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref(true);

    onMounted(() => {
      setCurrentPageTitle(t("meeting.meetings"));
    });

    return {
      t,
      moment,
      loading,
    };
  },
});
